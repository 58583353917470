body {
  font-family: $font-family;
  font-size: 14px;
  line-height: 1.5;
  color: $text-primary;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
p,
figure {
  margin: 0;
}

a {
  user-select: none;
  text-decoration: none;
  cursor: pointer;
}

ul,
nav {
  padding: 0;
  list-style: none;
}

address {
  font-style: normal;
}

section {
  border-radius: 4px;
}

label {
  cursor: pointer;
  user-select: none;
}

fieldset {
  padding: $unit-3;
  border-radius: 4px;
  border: 1px solid $input-border;
  margin: 0;
}

legend {
  color: $text-secondary;
  padding: 0 5px;
  font-weight: normal;
  letter-spacing: 0.5px;
  font-size: 16px;
}
