.partners-container-wrapper {
  /* width: 280px; */
  height: 100%;
  min-height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partners-container {
  height: 100%;
  width: 100%;
  max-width: 210px;
  max-height: 150px;
  font-size: 14px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
