.secondary-light {
  color: $secondary-light;
}

.grey {
  color: $text-secondary;
}

.success {
  color: $success;
}

.warning {
  color: $warning;
}

.error {
  color: $error;
}

.container {
  padding: $unit-2 $unit-4;
  max-width: $max-container-width;
  margin: auto;
  &:last-child {
    padding-bottom: $unit-4;
  }
}

.block {
  padding: $unit-3;
}

.link {
  font-family: $font-family;
  padding: 5px;
  font-weight: normal;
  font-size: 14px;
  transition: color $short $ease-in-out;
  border: none;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  &_cell {
    color: $text-secondary;
    font-size: 13px;
    font-weight: 600;
    padding: 5px 0;
  }
  &_large {
    font-size: 16px;
  }
  &:hover,
  &_active {
    color: $primary;
  }
}

.title {
  font-size: 24px;
  text-align: left;
  font-weight: normal;
  letter-spacing: 0.5px;
  &_small {
    font-size: 18px;
  }
  &_medium {
    font-size: 20px;
  }
}

.loaded {
  animation: fadeIn $shorter $ease-in-out forwards;
  &_long {
    animation-duration: $standard;
  }
}

.cropped {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.radio-title {
  margin-right: $unit-2;
  color: $text-secondary;
  padding-top: 12px;
  font-size: 16px;
  &_no-padding {
    padding-top: 0;
  }
}

.wrapper {
  padding: $unit-3;
  border-radius: 4px;
  border: 1px solid $input-border;
  overflow: hidden;
  &_tabs {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &_scroll {
    overflow-y: auto;
  }
}

.wrapper-header {
  display: flex;
  align-items: center;
  padding-left: $unit-3;
  margin-top: -$unit-3;
  margin-left: -$unit-3;
  margin-right: -$unit-3;
  margin-bottom: $unit-3;
  min-height: 56px;
  background-color: $default-background;
}

.wrapper-title {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.02857em;
}

.input-text {
  font-size: 16px;
}

.max-width {
  width: 100%;
}

.max-height {
  height: 100%;
}

.internal-table {
  position: relative;
  border: 1px solid $input-border;
  overflow: hidden;
  border-radius: 4px;
}

.table-actions {
  margin-left: -12px;
}
