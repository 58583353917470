@import '../../styles/variables';

.empty {
  padding: 62px 0;
  text-align: center;
  font-size: 16px;

  &__img {
    margin-bottom: 30px;
  }

  &__icon {
    position: relative;
    top: 6px;
    margin-right: $unit;
  }
}
