.f {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.f-i {
  display: inline-flex;
}

.fd-c {
  flex-direction: column;
}

.fd-rr {
  flex-direction: row-reverse;
}

.ai-c {
  align-items: center;
}

.ai-fs {
  align-items: flex-start;
}

.ai-fe {
  align-items: flex-end;
}

.as-fs {
  align-self: flex-start;
}

.as-c {
  align-self: center;
}

.as-fe {
  align-self: flex-end;
}

.jc-c {
  justify-content: center;
}

.jc-sb {
  justify-content: space-between;
}

.jc-sa {
  justify-content: space-around;
}

.jc-fe {
  justify-content: flex-end;
}

.fw-w {
  flex-wrap: wrap;
}

.fs-0 {
  flex-shrink: 0;
}

.fb-50 {
  flex-basis: 50%;
}

.fg-1 {
  flex-grow: 1;
}

.fg-2 {
  flex-grow: 2;
}

.p-r {
  position: relative;
}

.ws-nw {
  white-space: nowrap;
}

.mt {
  margin-top: $unit-3;
}

.mt-s {
  margin-top: $unit-2;
}

.mt-xs {
  margin-top: $unit;
}

.mt-l {
  margin-top: $unit-4;
}

.mb {
  margin-bottom: $unit-3;
}

.mb-s {
  margin-bottom: $unit-2;
}

.mb-l {
  margin-bottom: $unit-4;
}

.mr-s {
  margin-right: $unit-2;
}

.mr-xs {
  margin-right: $unit;
}

.ml-xs {
  margin-left: $unit;
}

.ml {
  margin-left: $unit-3;
}

.w-300 {
  width: 300px;
}
