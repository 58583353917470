@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.loader-enter {
  opacity: 0;
}

.loader-enter-active {
  opacity: 1;
  transition: opacity $short $ease-in-out;
}

.loader-exit {
  opacity: 1;
}

.loader-exit-active {
  opacity: 0;
  transition: opacity $short $ease-in-out;
}
