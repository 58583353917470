@import 'mixins';

$primary-light: #33ab9f;
$primary: #009688;
$primary-dark: #00695f;
$secondary-light: #607d8b;
$secondary: #455a64;
$secondary-dark: #263238;
$white: #eee;
$default-background: #f5f5f5;
$divider: #b4b4b4;
$text-primary: rgba(black, 0.87);
$text-secondary: rgba(black, 0.54);
$input-border: rgba(black, 0.23);
$success: #43a047;
$warning: #fb8c00;
$error: #f44336;

$font-family: 'Roboto', sans-serif;
$min-app-width: 1024px;
$max-container-width: 1400px;
$sidebar-width: 260px;

$unit: 8px;
$unit-2: $unit * 2;
$unit-3: $unit * 3;
$unit-4: $unit-2 * 2;

$short: 200ms;
$shorter: 225ms;
$standard: 500ms;
$long: 800ms;
$longest: 1s;

$ease-in-out: cubic-bezier(0.4, 0, 0.2, 1);
$ease-out: cubic-bezier(0, 0, 0.2, 1);
